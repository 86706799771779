import React from 'react'
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { mapToPost, formatTimeToRead } from "./knowledgebase-utils"
import PostCard from "./knowledgebase-post-card"
import styles from "./knowledgebase-post-list.module.scss"

export default ({ posts, headline }) => {

  return (<div className={styles.wrapper}>
    <h2 className={styles.title}>{headline}</h2>
    <div className={styles.items}>
      {posts.length === 0 ? 'Ничего не найдено': ''}
      {
        posts.map(
          edge => {
            const post = mapToPost(edge)
            const lead = false;
            return <PostCard key={post.title} post={post} />
          }
        )
      }
    </div>
  </div>)
}