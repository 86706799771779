import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import HeroKnowledgeBase from "../../components/pages/shared/hero/knowledge-base"
import SEO from "../../components/seo"
import Grid from "../../knowledgebase/components/knowledgebase-post-grid"
import PostList from "../../knowledgebase/components/knowledgebase-post-list"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import Layout from "../../components/layout"

export default function SearchPage({ location }) {
  const [results, setResults] = useState([])

  return <div>
    <Layout page="Поиск" headerColor={"#319DED"}>
      <HeroKnowledgeBase name={"knowledgebase"} onSearchResults={setResults} location={location} />
      <Grid fullWidth={true}>
         <PostList posts={ results } headline="Результаты поиска" />
      </Grid>
      <ContactFormComponent color={"#F9F9FA"} />
    </Layout>
  </div>
}

export const query = graphql`
query {
   KnowledgebaseIndex
}
`;